import React, { useState, useEffect, useContext } from 'react';
import { graphql } from 'gatsby';
import axios from 'axios';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import dayjs from 'dayjs';
import dayjsPluginUTC from 'dayjs-plugin-utc';
import AddToCalendarHOC, { SHARE_SITES } from 'react-add-to-calendar-hoc';
import { Context } from 'store/createContext';
import MEDIA from 'helpers/mediaTemplates';
import Layout from 'components/layout';
import Box from 'components/box';
import Title from 'components/title';
import Banner from 'components/banner';
import Ad from 'components/ad';
import { almostBlack, lightGrey, brNorm, rhythm } from 'constants/theme';

const Button = ({ children, onClick }) => {
  return (
    <button className="add-to-calendar-button" onClick={onClick}>
      {children}
    </button>
  );
};

const Dropdown = ({ children }) => {
  return <div className="add-to-calendar-dropdown">{children}</div>;
};

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const ResiReplayInfo = styled.div`
  padding: ${rhythm};
  border-radius: 0 0 ${brNorm} ${brNorm};
  background-color: ${almostBlack};
  color: ${lightGrey};
  text-align: center;
  line-height: 1.4;

  a {
    color: white;
    text-decoration: underline;
  }

  strong {
    color: white;
  }
`;

const StyledResiContainer = styled.div`
  width: 100%;
  margin: 4rem 0 6rem;
  padding: 0 4rem;

  .resi--container {
    width: 100%;
    position: relative;
    padding-bottom: 56.25%;
    border-radius: ${brNorm} ${brNorm} 0 0;
    overflow: hidden;
  }

  iframe {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }
`;

const StyledAdsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 100%);
  grid-template-rows: auto;
  margin-top: 1.25rem;
  margin: 1.25rem 2rem 0 2rem;

  ${MEDIA.DESKTOP`
    grid-template-columns: 100%;
  `};

  ${MEDIA.TABLET`
    margin: 2rem 0rem;
  `};
`;

const Index = ({ data, location }) => {
  //
  const AddToCalendarDropdown = AddToCalendarHOC(Button, Dropdown);
  dayjs.extend(dayjsPluginUTC);
  const { events } = data.allEventsJson.nodes[0];

  // Component State
  const [theEvent, setTheEvent] = useState(events[0]);
  const [toggleOn, setToggleOn] = useState(0);
  const [vipOnly, setVipOnly] = useState(0);
  const [bannerMessage, setBannerMessage] = useState('');
  const store = useContext(Context);
  const { eventTiming, setGlobalEventTiming } = store;

  useEffect(() => {
    (async function init() {
      await checkFeatureToggles();
      // getVip();
      getRsvp();
      websocketConnect();
      loadZendesk();
    })();
  }, []);

  useEffect(() => {
    // if (store.rsvp === 'flight-school') {
    //   store.setVerified('verified');
    // }
    store.setVerified('verified');

    checkEventStatus(toggleOn, vipOnly);
    // check for event status every 30 seconds
    const interval = setInterval(() => {
      checkEventStatus(toggleOn, vipOnly);
    }, 30000);
    return () => clearInterval(interval);
  }, [toggleOn, store.vip, vipOnly, store.rsvp]);

  const getVip = () => {
    const params = new URLSearchParams(location.search);
    const vipParam = params.get('vip');

    store.setVip(vipParam);
  };

  const getRsvp = () => {
    const params = new URLSearchParams(location.search);
    const rsvpParam = params.get('rsvp');

    store.setRsvp(rsvpParam);
  };

  const websocketConnect = () => {
    const heartbeat = ws => {
      ws.send('__beat__');
    };

    let ws;
    const connect = () => {
      let interval;

      ws = new WebSocket(
        'wss://prod.ws.businessmadesimple.com/app/bms-mastery'
      );

      // Setup our heartbeat to keep connection open
      ws.onopen = () => {
        console.log('Socket connected.');
        interval = setInterval(() => {
          heartbeat(ws);
        }, 30000);

        ws.send(
          JSON.stringify({
            event: 'pusher:subscribe',
            data: { auth: '', channel: 'mastery-api' },
          })
        );
      };

      // If heartbeat, ignore
      // Otherwise, update our store
      ws.onmessage = msg => {
        if (msg.data === '__alive__') return;
        const { data, event } = JSON.parse(msg.data);

        const _data = data ? JSON.parse(data) : null;

        if (event.includes('features.update')) {
          // if (event.includes('ToggleFeatureEvent')) {
          const toggle = _data.toggles.find(
            t => t.feature === 'force_bootcamp'
          );
          setToggleOn(toggle.on);

          const vip = _data.toggles.find(t => t.feature === 'bootcamp_vip');
          setVipOnly(vip.on);

          const banner = _data.toggles.find(
            t => t.feature === 'workshop_banner'
          );
          if (banner.on) {
            setBannerMessage(banner.additional);
          } else {
            setBannerMessage('');
          }
        }
      };

      // Clear our heartbeat if websocket closes unexpectedly
      ws.onclose = () => {
        ws = null;
        clearInterval(interval);
        console.log('Socket connection has closed.');
      };

      ws.onerror = error => {
        console.log('Socket error: ', { error });
      };
    };

    const reconnect = async () => {
      try {
        await connect();
      } catch (error) {
        console.log('Error reconnecting to websocket: Error ', { error });
      }
    };

    // Initial socket connection
    try {
      connect();
    } catch (error) {
      console.log('Socket error connecting.', error);
    }

    // Reconnect to socket if we lose connection
    setInterval(() => {
      if (!ws) {
        reconnect();
      }
    }, 5000);
  };

  const loadZendesk = () => {
    let zdscript = document.createElement('script');
    zdscript.setAttribute('id', 'ze-snippet');
    zdscript.src = `https://static.zdassets.com/ekr/snippet.js?key=${process.env.GATSBY_ZENDESK_KEY}`;
    document.getElementsByTagName('body')[0].appendChild(zdscript);

    if (window) {
      var waitForZopim = setInterval(function() {
        if (
          window.$zopim === undefined ||
          window.$zopim.livechat === undefined
        ) {
          return;
        }
        window.$zopim.livechat.badge.hide();
        clearInterval(waitForZopim);
      }, 100);
    }
  };

  const checkFeatureToggles = () => {
    return axios
      .get(process.env.GATSBY_MASTERY_API)
      .then(function(response) {
        // handle success
        if (response.data) {
          const toggle = response.data.find(
            f => f.feature === 'force_bootcamp'
          );
          setToggleOn(toggle.on);

          const vip = response.data.find(t => t.feature === 'bootcamp_vip');
          setVipOnly(vip.on);

          const banner = response.data.find(
            t => t.feature === 'workshop_banner'
          );

          if (banner.on) {
            setBannerMessage(banner.additional);
          } else {
            setBannerMessage('');
          }
        }
      })
      .catch(function(error) {
        // handle error
        // console.log(error);
      });
  };

  const checkEventStatus = async (FEATURE_TOGGLE, VIP_ONLY) => {
    // Get event to display
    const now = dayjs.utc();

    ////
    //
    // figure out what events and past, present, future
    //
    ////

    // if right now during an events time
    const currentlyHappening = events.filter(event => {
      const endTime = store.vip ? 10 : 10;
      const start = dayjs.utc(event.start_date_utc);
      const end = dayjs.utc(event.end_date_utc);
      const eventLength = start.diff(end, 'minute');
      const diff = start.diff(now, 'minute');
      return diff <= 15 && diff >= eventLength - endTime;
    });

    // if event is recently ended
    const recentlyEndedEvents = events.filter(event => {
      const end = dayjs.utc(event.end_date_utc);
      const diff = end.diff(now, 'minute');

      const endTime = store.vip ? 10 : 10;

      return diff < -endTime && diff > -60 - endTime;
    });

    // any events in the future
    const upcomingEvents = events.filter(event => {
      const start = dayjs.utc(event.start_date_utc);
      const diff = start.diff(now, 'minute');
      return diff > 0;
    });

    ////
    //
    // check feature toggles and VIP status and short circuit outta here if anything is forced on/off
    //
    ////

    if (FEATURE_TOGGLE) {
      if (eventTiming !== 'currently') {
        setGlobalEventTiming('currently');
        setTheEvent(events[0]);
      }
      return;
    }

    if (VIP_ONLY) {
      if (store.vip) {
        if (eventTiming !== 'currently') {
          setGlobalEventTiming('currently');
          setTheEvent(events[0]);
        }
        return;
      } else {
        if (eventTiming !== 'over') {
          setGlobalEventTiming('over');
          setTheEvent(events[0]);
          return;
        }
      }
    }

    ////
    //
    // set current state of events based on above
    //
    ////

    if (
      upcomingEvents.length &&
      !currentlyHappening.length &&
      !recentlyEndedEvents.length
    ) {
      if (eventTiming !== 'upcoming') {
        setGlobalEventTiming('upcoming');
      }
      setTheEvent(upcomingEvents[0]);
    }

    if (currentlyHappening.length) {
      if (eventTiming !== 'currently') {
        setGlobalEventTiming('currently');
      }
      setTheEvent(currentlyHappening[0]);
    }

    if (recentlyEndedEvents.length) {
      if (eventTiming !== 'over') {
        setGlobalEventTiming('over');
      }
      setTheEvent(recentlyEndedEvents[0]);
    }
  };

  ////
  //
  // misc
  //
  ////

  const StartDateInCentralTime = dayjs(theEvent.start_date_central);

  // Choosing the ad
  const { ads } = data.allAdsJson.nodes[0];

  // Calendar Event
  const calendarEvent = event => {
    // let vip = store ? store.vip : '';
    let rsvp = store ? store.rsvp : '';
    let url = store.vip
      ? 'https://bootcamp.businessmadesimple.com/?rsvp=' + rsvp
      : 'https://bootcamp.businessmadesimple.com';
    let eventLength = event.description.indexOf('day 2') > -1 ? 0 : 1;
    return {
      description: event.description,
      duration: 3.5,
      endDatetime: dayjs(theEvent.end_date_central)
        .add(eventLength, 'day')
        .format('YYYYMMDDTHHmmss'),
      location: url,
      startDatetime: dayjs(theEvent.start_date_central).format(
        'YYYYMMDDTHHmmss'
      ),
      title: 'Mid-Year Revenue Accelerator',
      timezone: 'America/Chicago',
    };
  };

  return (
    <Layout bannerMessage={bannerMessage}>
      <Box>
        <Title as="h2" size="large">
          {data.homeJson.content.childMarkdownRemark.rawMarkdownBody}
          {/* {eventTiming} */}
        </Title>
        {eventTiming === 'currently' ? (
          store.verifiedRsvp !== 'verified' ? (
            <div style={{ maxWidth: '600px', margin: '20px auto' }}>
              <h2>
                You are not authorized to access the live stream. Please check
                that you are using the correct link to access the live stream.
              </h2>
            </div>
          ) : (
            <>
              <StyledResiContainer>
                <div className="resi--container">
                  <iframe
                    title="Mid-Year Revenue Accelerator"
                    allow="autoplay; fullscreen"
                    allowFullScreen={true}
                    src={`https://control.resi.io/webplayer/video.html?id=${theEvent.embed_code}&iframe=1&autoplay=1`}
                    style={{ width: '100%' }}
                  ></iframe>
                </div>
                <ResiReplayInfo className="resi--replay-info">
                  You will have access to the replay of the livestream until
                  August 18, 2023. You will receive an email to the address you
                  used for registration. Have questions about the stream? Get
                  answers{' '}
                  <a
                    href={
                      'https://businessmadesimple.com/mid-year-revenue-accelerator-faqs/'
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    <strong>HERE.</strong>
                  </a>
                </ResiReplayInfo>
              </StyledResiContainer>
            </>
          )
        ) : (
          <Banner>
            <BackgroundImage
              Tag="div"
              fluid={theEvent.banner_image.source.childImageSharp.fluid}
              className="banner-background"
              style={{
                backgroundPosition: 'center center',
                width: '100%',
                borderRadius: '3rem',
                overflow: 'hidden',
              }}
            >
              <div className="banner-content">
                <h3 className="banner-content__title">
                  {data.bannerJson.title}
                </h3>
                {eventTiming === 'upcoming' && (
                  <p className="banner-content__about">
                    The Livestream will start on this page on:
                  </p>
                )}
                {eventTiming === 'upcoming' ? (
                  <>
                    <p className="banner-content__start-date">
                      {StartDateInCentralTime.format('dddd MMMM D, YYYY')} at
                      9:00am CT
                    </p>
                  </>
                ) : (
                  <div className="banner-content__over">This event is over</div>
                )}
                {eventTiming === 'over' && (
                  <>
                    <p className="banner-content__about">
                      You will have access to the replay of the livestream until
                      August 18, 2023. You will receive an email to the address
                      you used for registration. Have questions about the
                      stream? Get answers{' '}
                      <a
                        href={
                          'https://businessmadesimple.com/mid-year-revenue-accelerator-faqs/'
                        }
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: 'white', textDecoration: 'underline' }}
                      >
                        <strong>HERE.</strong>
                      </a>
                    </p>
                  </>
                )}
                {eventTiming === 'upcoming' &&
                store.verifiedVip === 'verified' ? (
                  <AddToCalendarDropdown
                    className="add-to-calendar"
                    buttonText="Add to my calendar"
                    event={calendarEvent(theEvent)}
                    items={[SHARE_SITES.GOOGLE, SHARE_SITES.ICAL]}
                  />
                ) : null}
              </div>
              {eventTiming === 'upcoming' ? (
                <ResiReplayInfo className="resi--replay-info">
                  You will have access to the replay of the livestream until
                  August 18, 2023. You will receive an email to the address you
                  used for registration. Have questions about the stream? Get
                  answers{' '}
                  <a
                    href={
                      'https://businessmadesimple.com/mid-year-revenue-accelerator-faqs/'
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    <strong>HERE.</strong>
                  </a>
                </ResiReplayInfo>
              ) : null}
            </BackgroundImage>
          </Banner>
        )}

        <StyledAdsContainer>
          {ads.map(ad => (
            <Ad ad={ad} key={ad.title} />
          ))}
        </StyledAdsContainer>
      </Box>
    </Layout>
  );
};

Index.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query HomepageQuery {
    homeJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
    }
    bannerJson {
      title
      image {
        alt
        source {
          childImageSharp {
            fluid(maxHeight: 900, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    allEventsJson {
      nodes {
        events {
          additional
          cta
          description
          embed_code
          start_date_utc
          end_date_utc
          start_date_central
          end_date_central
          id
          name
          workbook_download
          guide_workbook_download
          execution_workbook_download
          brandscript_download
          banner_image {
            alt
            source {
              childImageSharp {
                fluid(maxHeight: 900, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        id
      }
    }
    allAdsJson {
      nodes {
        ads {
          copy
          cta
          cta_link
          image {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          title
          subTitle
        }
      }
    }
    imageSharp(original: { src: { regex: "/Worksheet/" } }) {
      gatsbyImageData
      fluid(maxWidth: 30) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;
